import { Link, useNavigate } from 'react-router-dom';
import imagemVaniaHome from './assets/imagem-vania-home.png'
import imagemAfrodite from './assets/imagem-afrodite.jpg'
import imagemVenus from './assets/imagem-venus.jpg'
import imagemHera from './assets/imagem-hera.jpg'
import seta from './assets/seta.png'
import imagemSalto from './assets/imagem-salto-metodo.png'
import imagemBolsa from './assets/imagem-bolsa-metodo.png'
import imagemVaniaQuemSou from './assets/imagem-vania-quemsou.png'
import Cabecalho from "../../componentes/Cabecalho"
import BarraNavegacao from "../../componentes/BarraNavegacao"
import Rodape from "../../componentes/Rodape"
import "./Home.css"

const Home = () => {
    const navigate = useNavigate();

    return (
        <div className="main">
            <header>
                <Cabecalho />
                <BarraNavegacao />
            </header>
            <div className="corpo">
                <div className="home" id="home">
                    <div className="bloco-preto">
                        <h1>Vânia</h1>
                        <img className="logo" src={imagemVaniaHome} width={200} alt="Imagem Vânia Gonzalez" />
                        <h2>Gonzalez</h2>
                    </div>
                    <span>"Moda e imagem pessoal: uma combinação poderosa que revela sua verdadeira essência e eleva sua confiança."</span>
                </div>
                <div className="consultoria-imagem" id="consultoria-imagem">
                    <h1>CONSULTORIA DE IMAGEM</h1>
                    <span>A consultoria de imagem do método Visual Magnético é uma experiência única e abrangente, especialmente projetada para revelar a sua essência autêntica por meio da moda e do estilo pessoal.</span>
                    <div className="servicos">
                        <div className="consultoria-afrodite">
                            <span>Afrodite</span>
                            <img className="imagem-afrodite" src={imagemAfrodite} width={310} alt="Imagem Consultoria Afrodite" />
                            <button onClick={(e) => {navigate('/afrodite')}}>Saiba mais</button>
                        </div>
                        <div className="consultoria-venus">
                            <span>Vênus</span>
                            <img className="imagem-venus" src={imagemVenus} width={310} alt="Imagem Consultoria Vênus" />
                            <button onClick={(e) => {navigate('/venus')}}>Saiba mais</button>
                        </div>
                        <div className="consultoria-hera">
                            <span>Hera</span>
                            <img className="imagem-hera" src={imagemHera} width={310} alt="Imagem Consultoria Hera" />
                            <button onClick={(e) => {navigate('/hera')}}>Saiba mais</button>
                        </div>
                    </div>
                </div>
                <div className='metodo' id='metodo'>
                    <div className='titulo-imagem'>
                        <img className="seta" src={seta} width={150} alt="Seta" />
                        <div className='titulo'>
                            <h1>O QUE É O MÉTODO</h1>
                            <h1>VISUAL MAGNÉTICO?</h1>
                        </div>
                    </div>
                    <div className='dizeres-e-imagens-metodo'>
                        <div className='dizeres-metodo'>
                            <span>O método Visual Magnético é uma consultoria de imagem e estilo com abordagem psicológica, criada para transformar a aparência e elevar a autoconfiança das mulheres. Essa abordagem combina a expertise em moda e estilo com um profundo entendimento das necessidades e personalidade de cada cliente.</span>
                            <br></br>
                            <br></br>
                            <span>O método vai além de simplesmente mudar a aparência; é uma experiência transformadora que fortalece a autoestima, permitindo que cada mulher se destaque com uma presença magnética e inspiradora em todos os aspectos de sua vida.</span>
                        </div>
                        <div className='imagens-metodo'>
                            <img className="imagem-salto" src={imagemSalto} width={120} alt="Imagem de um salto" />
                            <img className="imagem-bolsa" src={imagemBolsa} width={120} alt="Imagem de uma bolsa" />
                        </div>
                    </div>
                </div>
                <div className='quem-sou' id='quem-sou'>
                    <img className="imagem-vania-quem-sou" src={imagemVaniaQuemSou} width={350} alt="Imagem Vânia Gonzalez" />
                    <div className='titulo-e-dizeres-quem-sou'>
                        <h1>Conheça sua Consultora</h1>
                        <span>Psicóloga da moda especialista em comportamento humano, empresária, mentora de mulheres, consultora de imagem e estilo.</span>
                        <br></br>
                        <br></br>
                        <span>Criadora do Visual Magnético®, método que está elevando a marca pessoal de muitas mulheres e transformando suas vidas.</span>
                        <br></br>
                        <br></br>
                        <span>Atua como psicóloga comportamental há 12 anos e já transformou a vida de mais de 3.000 mulheres. Agora, vai te ajudar a elevar sua autoconfiança, autenticidade e poder de atração por meio de uma imagem marcante e inspiradora.</span>
                    </div>
                </div>
            </div>
            <footer className='contato' id='contato'>
                <Rodape />
            </footer>
        </div>
    );
}

export default Home