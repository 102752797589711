import React, {useState} from "react";
import logo from './assets/logo.png'
import "./BarraNavegacao.css"

const BarraNavegacao = () => {

    // // to change burger classes
    // const [active, setActive] = useState("navbar")
    // const [isMenuClicked, setIsMenuClicked] = useState(false)

    // // toggle burger menu change
    // const updateMenu = () => {
    //     if(!isMenuClicked) {
    //         setActive("nav active")
    //     }
    //     else {
    //         setActive("navbar")
    //     }
    //     setIsMenuClicked(!isMenuClicked)
    // }

    return (
        <nav className="navbar">
            <h1 className="logo">
                <img className="logo" src={logo} alt="Logo Visual Magnético" />
            </h1>
            <div className="dizeres-logo">
                <h1>PSICOLOGIA DA MODA</h1>
                <span>by Vânia Gonzalez</span>
            </div>
            
            {/* <div className="burger-menu" onClick={updateMenu}>
                <div className={burger_class}></div>
                <div className={burger_class}></div>
                <div className={burger_class}></div>
            </div> */}

            {/* <button
                className="btn-menu"
                aria-label="Abrir Menu"
                aria-haspopup="true"
                aria-controls="menu"
                aria-expanded="false"
                >
                Menu<span className="hamburger"></span>
            </button> */}

            <ul className="navegacao" id="menu" role="menu">
                <li><a href="#home">HOME</a></li>
                <li><a href='#consultoria-imagem'>CONSULTORIA DE IMAGEM</a></li>
                <li><a href='#metodo'>MÉTODO</a></li>
                <li><a href='#quem-sou'>QUEM SOU</a></li>
                <li><a href='#contato'>CONTATO</a></li>
                {/* <li><img className="lupa" src={lupa} alt="Ícone de lupa" /></li> */}
            </ul>
        </nav>
    );
}

export default BarraNavegacao