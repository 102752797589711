import { Route, Routes } from "react-router-dom"
import Afrodite from "../paginas/Afrodite"
import Home from "../paginas/Home"
import Hera from "../paginas/Hera"
import Venus from "../paginas/Venus"


const Rotas = () => {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/afrodite' element={<Afrodite />} />
            <Route path='/hera' element={<Hera />} />
            <Route path='/venus' element={<Venus />} />
        </Routes>
    )
}

export default Rotas