import instagram from './assets/icone-instagram.png'
import email from './assets/icone-email.png'
import facebook from './assets/icone-facebook.png'
import "./Cabecalho.css"

const Cabecalho = () => {
    return (
        <div className="cabecalho">
            <div className='icones'>
                <a href='https://www.instagram.com/vaniagonzalezoficial/'><img className="icone-instagram" src={instagram} width={30} height={30} alt="Ícone do Instagram" /></a>
                <a href='mailto:contato@vaniagonzalez.com.br'><img className="icone-email" src={email} width={30} height={30} alt="Ícone do E-mail" /></a>
                <a href='https://www.facebook.com/vania.gonzalez.7334'><img className="icone-facebook" src={facebook} width={30} height={30} alt="Ícone do Facebook" /></a>
            </div>
        </div>
    );
}

export default Cabecalho