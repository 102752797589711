import imagemServico from './assets/imagem-descricao-servico-venus.png'
import imagemBeneficios from './assets/imagem-beneficios-consultoria-venus.png'
import imagemMaterial1 from '../Afrodite/assets/imagem-material-personalizado-afrodite-1.png'
import imagemMaterial2 from '../Afrodite/assets/imagem-material-personalizado-afrodite-2.png'
import imagemMaterial3 from '../Afrodite/assets/imagem-material-personalizado-afrodite-3.png'
import iconeCheckMaterial from '../Afrodite/assets/icone-check-material-personalizado.png'
import imagemBonus1 from '../Afrodite/assets/imagem-bonus-afrodite-1.png'
import imagemBonus2 from '../Afrodite/assets/imagem-bonus-afrodite-2.png'
import imagemBonus3 from '../Afrodite/assets/imagem-bonus-afrodite-3.png'
import iconeCheckBonus from '../Afrodite/assets/icone-check-bonus.png'
import Cabecalho from "../../componentes/Cabecalho"
import Rodape from "../../componentes/Rodape"
import ScrollToTop from "../../componentes/ScrollUp"
import "./Venus.css"

const Venus = () => {
    return (
        <div className="main">
            <ScrollToTop />
            <header>
                <Cabecalho />
            </header>
            <div className="corpo-venus">
                <div className="titulo-e-subtitulo">
                    <div>
                        <h1>CONSULTORIA DE IMAGEM</h1>
                        <h2>Vênus</h2>
                    </div>
                </div>
                <div className="descricao-servico-venus">
                    <div className='imagens-servico-venus'>
                        <img className="imagem-servico-venus" src={imagemServico} width={350} height={350} alt="Imagem de uma mulher trabalhando" />
                    </div>
                    <div className="dizeres-descricao-venus">
                        <h1>DESCRIÇÃO</h1>
                        <h2>do Serviço</h2>
                        <span>Serão três encontros, dois presenciais com duração de duas horas cada e um online (via Zoom ou Google Meet) com duração de no máximo duas horas.</span>
                        <h3>PRIMEIRO ENCONTRO - Presencial</h3>
                        <span><b>Coloração Pessoal:</b> identificação das cores que mais realçam sua pele, olhos e cabelos, levando em conta fatores como temperatura e intensidade das cores.</span>
                        <br></br><br></br>
                        <span><b>Análise de Biotipo:</b> considera sua estrutura corporal, incluindo proporções, curvas e linhas, para escolher roupas que valorizem sua silhueta.</span>
                        <br></br><br></br>
                        <span><b>Análise Facial:</b> avaliação da forma do rosto, características dominantes e simetria para recomendar cortes de cabelo e óculos ideais.</span>
                        <h3>SEGUNDO ENCONTRO - Presencial</h3>
                        <span><b>Guarda-roupa Inteligente:</b> análise das suas roupas, sapatos e acessórios, identificando peças-chave e faltantes. Crio uma lista de compras estratégica e monto looks variados, ensinando técnicas de combinação e sobreposição para diferentes ocasiões.</span>
                        <h3>TERCEIRO ENCONTRO - Online</h3>
                        <span>Orientações, entrega e apresentação do seu <b>Dossiê Personalizado.</b> Este dossiê foi preparado para ajudar a destacar o melhor de você, focando em aspectos que acentuam sua beleza natural e promovem sua confiança pessoal.</span>
                    </div>
                </div>
                <div className="beneficios-venus">
                    <img className="imagem-beneficios-venus" src={imagemBeneficios} width={350} height={350} alt="Imagem de uma mulher bem vestida e completamente de branco" />
                    <div className="dizeres-beneficios-venus">
                        <h1>BENEFÍCIOS</h1>
                        <h2>da Consultoria</h2>
                        <span><b>Aumento da autoconfiança:</b> sentir-se mais segura e confortável com sua aparência e estilo;</span>
                        <br></br><br></br>
                        <span><b>Bem-estar emocional:</b> melhoria no bem-estar emocional, devido ao aumento da autoestima e autoconfiança;</span>
                        <br></br><br></br>
                        <span><b>Tomada de decisões estratégicas:</b> habilidade em fazer compras assertivas, evitando aquisições do que não funciona para você;</span>
                        <br></br><br></br>
                        <span><b>Combinando cores:</b> capacidade de combinar cores para ressaltar a sua beleza.</span>
                        <br></br><br></br>
                        <span><b>Habilidade de montar looks versáteis:</b> capacidade de criar diferentes looks para diversas ocasiões, utilizando as mesmas peças de roupas de formas inovadoras.</span>
                    </div>
                </div>
                <div className='material-personalizado-venus'>
                    <div className='titulo-e-subtitulo-material-personalizado-venus'>
                        <h1>MATERIAL</h1>
                        <h2>Personalizado</h2>
                    </div>
                    <div className='conteudo-material-personalizado-venus'>
                        <h3>O que você vai receber no final da consultoria:</h3>
                        <div className='bloco-cinza-venus'>
                            <div className='dizeres-bloco-cinza-venus'>
                                <div className='itens'>
                                    <img className="icone-check-material" src={iconeCheckMaterial} width={25} height={25} alt="Ícone de check" />
                                    <h2>CARTELA DE CORES</h2>
                                </div>
                                <div className='itens'>
                                    <img className="icone-check-material" src={iconeCheckMaterial} width={25} height={25} alt="Ícone de check" />
                                    <h2>CÍRCULO CROMÁTICO</h2>
                                </div>
                                <div className='itens'>
                                    <img className="icone-check-material" src={iconeCheckMaterial} width={25} height={25} alt="Ícone de check" />
                                    <h2>DOSSIÊ PERSONALIZADO CONTENDO:</h2>
                                </div>
                                <ul>
                                    <li>Indicação das cores que ressaltam sua beleza;</li>
                                    <li>Indicação de cortes de cabelo e maquiagens;</li>
                                    <li>Informações de como valorizar o seu biotipo;</li>
                                    <li>Informações de como valorizar as suas formas faciais;</li>
                                    <li>Lista de compras.</li>
                                </ul>
                            </div>
                            <div className='imagens-bloco-cinza-venus'>
                                <img className="imagem-material-venus-1" src={imagemMaterial1} width={200} alt="Imagem de uma mulher trabalhando" />
                                <img className="imagem-material-venus-2" src={imagemMaterial2} width={200} alt="Imagem de uma cartela de cores" />
                                <img className="imagem-material-venus-3" src={imagemMaterial3} width={200} alt="Imagem de um círculo cromático" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bonus-venus'>
                    <h1>BÔNUS</h1>
                    <h2>Exclusivo</h2>
                    <div className='bloco-cinza-venus'>
                        <div className='dizeres-bloco-cinza-venus'>
                            <div className='itens'>
                                <img className="icone-check-bonus" src={iconeCheckBonus} width={40} height={40} alt="Ícone de check" />
                                <span>O meu GUIA: Básica com Estilo – para um guarda-roupa versátil e elegante;</span>
                            </div>
                            <br></br>
                            <div className='itens'>
                                <img className="icone-check-bonus" src={iconeCheckBonus} width={40} height={40} alt="Ícone de check" />
                                <span>Acesso a minha pasta exclusiva do Pinterest, na qual compartilho dicas de tendências e looks para todas as estações do ano;</span>
                            </div>
                            <br></br>
                            <div className='itens'>
                                <img className="icone-check-bonus" src={iconeCheckBonus} width={40} height={40} alt="Ícone de check" />
                                <span>Presente personalizado.</span>
                            </div>
                        </div>
                        <div className='imagens-bloco-cinza-bonus-venus'>
                            <img className="imagem-bonus-venus-1" src={imagemBonus1} width={200} alt="Capa do guia Básica com Estilo" />
                            <img className="imagem-bonus-venus-2" src={imagemBonus2} width={200} alt="Imagem de uma xícara de café em cima de uma revista da Vogue" />
                            <img className="imagem-bonus-venus-3" src={imagemBonus3} width={200} alt="Imagem de uma mulher bem vestida e completamente de preto" />
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <Rodape />
            </footer>
        </div>
    );
}

export default Venus