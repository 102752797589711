import imagemServico from './assets/imagem-descricao-servico-hera.png'
import imagemBeneficios from './assets/imagem-beneficios-consultoria-hera.png'
import imagemMaterial1 from '../Afrodite/assets/imagem-material-personalizado-afrodite-1.png'
import imagemMaterial2 from '../Afrodite/assets/imagem-material-personalizado-afrodite-2.png'
import imagemMaterial3 from './assets/imagem-material-hera.png'
import iconeCheckMaterial from '../Afrodite/assets/icone-check-material-personalizado.png'
import imagemBonus1 from '../Afrodite/assets/imagem-bonus-afrodite-1.png'
import imagemBonus2 from './assets/imagem-bonus-hera-2.png'
import imagemBonus3 from './assets/imagem-bonus-hera-3.png'
import iconeCheckBonus from '../Afrodite/assets/icone-check-bonus.png'
import Cabecalho from "../../componentes/Cabecalho"
import Rodape from "../../componentes/Rodape"
import ScrollToTop from "../../componentes/ScrollUp"
import "./Hera.css"

const Hera = () => {
    return (
        <div className="main">
            <ScrollToTop />
            <header>
                <Cabecalho />
            </header>
            <div className="corpo-hera">
                <div className="titulo-e-subtitulo">
                    <div>
                        <h1>CONSULTORIA DE IMAGEM</h1>
                        <h2>Hera</h2>
                    </div>
                </div>
                <div className="descricao-servico-hera">
                    <div className='imagens-servico-hera'>
                        <img className="imagem-servico-hera" src={imagemServico} width={350} height={350} alt="Imagem de uma mulher trabalhando" />
                    </div>
                    <div className="dizeres-descricao-hera">
                        <h1>DESCRIÇÃO</h1>
                        <h2>do Serviço</h2>
                        <span>Serão oito encontros no total, com uma ou duas sessões por semana – dependendo da sua disponibilidade. Três encontros  presenciais com duração de duas horas cada e cinco online (via Zoom ou Google Meet) com duração de uma hora e meia cada.</span>
                        <br></br><br></br>
                        <span><b>Nos três primeiros encontros,</b> trabalharemos o primeiro pilar do método: AUTOCONHECIMENTO. O objetivo deste pilar é trazer à consciência aspectos importantes da sua personalidade, o que ajudará na construção da sua imagem pessoal. Nesta etapa, utilizaremos quatro ferramentas específicas.</span>
                        <br></br><br></br>
                        <span><b>No quarto, quinto e sexto encontros,</b> focaremos no segundo pilar do método: EMPODERAMENTO. Nesta fase, montaremos uma estratégia para a sua nova imagem pessoal, utilizando mais quatro ferramentas.</span>
                        <br></br><br></br>
                        <span><b>Os dois últimos encontros</b> da consultoria serão dedicados ao terceiro pilar: INTEGRAÇÃO E AÇÃO. Este é o momento de unir todas as técnicas para finalizar o processo e observar o resultado. O objetivo desta última etapa é colocar você no comando da sua nova imagem pessoal. Você será capaz de fazer escolhas assertivas e se sentirá mais segura para escrever um novo capítulo da sua história.</span>
                    </div>
                </div>
                <div className="beneficios-hera">
                    <img className="imagem-beneficios-hera" src={imagemBeneficios} width={350} height={350} alt="Imagem de uma mulher bem vestida e completamente de preto" />
                    <div className="dizeres-beneficios-hera">
                        <h1>BENEFÍCIOS</h1>
                        <h2>da Consultoria</h2>
                        <span><b>Aumento da autoconfiança:</b> sentir-se mais segura e confortável com sua aparência e estilo;</span>
                        <br></br><br></br>
                        <span><b>Clareza de identidade:</b> entendimento profundo da sua personalidade e como ela se reflete na sua imagem pessoal;</span>
                        <br></br><br></br>
                        <span><b>Guarda-roupa funcional:</b> um guarda-roupa organizado e eficiente, com peças que combinam entre si e com seu estilo de vida;</span>
                        <br></br><br></br>
                        <span><b>Imagem profissional alinhada:</b> uma imagem que transmite profissionalismo e autenticidade, fortalecendo sua presença no ambiente de trabalho.</span>
                        <br></br><br></br>
                        <span><b>Tomada de decisões estratégicas:</b> habilidade em fazer compras conscientes, evitando aquisições impulsivas e desnecessárias;</span>
                        <br></br><br></br>
                        <span><b>Habilidade de montar looks versáteis:</b> capacidade de criar diferentes looks para diversas ocasiões, utilizando as mesmas peças de roupas de formas inovadoras;</span>
                        <br></br><br></br>
                        <span><b>Bem-estar emocional:</b> melhoria no bem-estar emocional, devido ao aumento da autoestima e autoconfiança;</span>
                        <br></br><br></br>
                        <span><b>Melhoria na comunicação não verbal:</b> uso da imagem pessoal para comunicar melhor suas intenções e valores, tanto no âmbito pessoal quanto profissional.</span>
                    </div>
                </div>
                <div className='material-personalizado-hera'>
                    <div className='titulo-e-subtitulo-material-personalizado-hera'>
                        <h1>MATERIAL</h1>
                        <h2>Personalizado</h2>
                    </div>
                    <div className='conteudo-material-personalizado-hera'>
                        <h3>O que você vai receber no final da consultoria:</h3>
                        <div className='bloco-cinza-hera'>
                            <div className='dizeres-bloco-cinza-hera'>
                                <div className='itens'>
                                    <img className="icone-check-material" src={iconeCheckMaterial} width={25} height={25} alt="Ícone de check" />
                                    <h2>CARTELA DE CORES</h2>
                                </div>
                                <div className='itens'>
                                    <img className="icone-check-material" src={iconeCheckMaterial} width={25} height={25} alt="Ícone de check" />
                                    <h2>CÍRCULO CROMÁTICO</h2>
                                </div>
                                <div className='itens'>
                                    <img className="icone-check-material" src={iconeCheckMaterial} width={25} height={25} alt="Ícone de check" />
                                    <h2>DOSSIÊ PERSONALIZADO CONTENDO:</h2>
                                </div>
                                <ul>
                                    <li>Estratégia de estilo e imagem pessoal;</li>
                                    <li>Indicação das cores que ressaltam sua beleza;</li>
                                    <li>Indicação de cortes de cabelo e maquiagens;</li>
                                    <li>Informações de como valorizar o seu biotipo;</li>
                                    <li>Informações de como valorizar as suas formas faciais;</li>
                                    <li>Lookbook (looks montados);</li>
                                    <li>Seu guarda-roupa ideal.</li>
                                </ul>
                            </div>
                            <div className='imagens-bloco-cinza-hera'>
                                <img className="imagem-material-hera-1" src={imagemMaterial1} width={200} alt="Imagem de uma mulher trabalhando" />
                                <img className="imagem-material-hera-2" src={imagemMaterial2} width={200} alt="Imagem de uma cartela de cores" />
                                <img className="imagem-material-hera-3" src={imagemMaterial3} width={200} alt="Imagem de uma mulher bem vestida" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bonus-hera'>
                    <h1>BÔNUS</h1>
                    <h2>Exclusivo</h2>
                    <div className='bloco-cinza-hera'>
                        <div className='dizeres-bloco-cinza-hera'>
                            <div className='itens'>
                                <img className="icone-check-bonus" src={iconeCheckBonus} width={40} height={40} alt="Ícone de check" />
                                <span>Ao longo de um mês (após termino da consultoria), você terá ACESSO IRRESTRITO para esclarecer dúvidas e compartilhar seus looks, recebendo orientações personalizadas para implementar os conhecimentos adquiridos durante a consultoria;</span>
                            </div>
                            <br></br>
                            <div className='itens'>
                                <img className="icone-check-bonus" src={iconeCheckBonus} width={40} height={40} alt="Ícone de check" />
                                <span>O meu GUIA: Básica com Estilo – para um guarda-roupa versátil e elegante;</span>
                            </div>
                            <br></br>
                            <div className='itens'>
                                <img className="icone-check-bonus" src={iconeCheckBonus} width={40} height={40} alt="Ícone de check" />
                                <span>Acesso a minha pasta exclusiva do Pinterest, na qual compartilho dicas de tendências e looks para todas as estações do ano;</span>
                            </div>
                            <br></br>
                            <div className='itens'>
                                <img className="icone-check-bonus" src={iconeCheckBonus} width={40} height={40} alt="Ícone de check" />
                                <span>Presente personalizado.</span>
                            </div>
                        </div>
                        <div className='imagens-bloco-cinza-bonus-hera'>
                            <img className="imagem-bonus-hera-1" src={imagemBonus1} width={200} alt="Capa do guia Básica com Estilo" />
                            <img className="imagem-bonus-hera-2" src={imagemBonus2} width={200} alt="Imagem de uma mulher segurando o celular" />
                            <img className="imagem-bonus-hera-3" src={imagemBonus3} width={200} alt="Imagem de uma revista da Vogue e roupas" />
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <Rodape />
            </footer>
        </div>
    );
}

export default Hera