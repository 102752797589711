import imagemServico1 from './assets/imagem-descricao-servico-afrodite-1.png'
import imagemBeneficios from './assets/imagem-beneficios-consultoria-afrodite.png'
import imagemMaterial1 from './assets/imagem-material-personalizado-afrodite-1.png'
import imagemMaterial2 from './assets/imagem-material-personalizado-afrodite-2.png'
import imagemMaterial3 from './assets/imagem-material-personalizado-afrodite-3.png'
import imagemBonus1 from './assets/imagem-bonus-afrodite-1.png'
import imagemBonus2 from './assets/imagem-bonus-afrodite-2.png'
import imagemBonus3 from './assets/imagem-bonus-afrodite-3.png'
import iconeCheckMaterial from './assets/icone-check-material-personalizado.png'
import iconeCheckBonus from './assets/icone-check-bonus.png'
import Cabecalho from "../../componentes/Cabecalho"
import Rodape from "../../componentes/Rodape"
import ScrollToTop from "../../componentes/ScrollUp"
import "./Afrodite.css"

const Afrodite = () => {
    return (
        <div className="main">
            <ScrollToTop />
            <header>
                <Cabecalho />
            </header>
            <div className="corpo-afrodite">
                <div className="titulo-e-subtitulo">
                    <div>
                        <h1>CONSULTORIA DE IMAGEM</h1>
                        <h2>Afrodite</h2>
                    </div>
                </div>
                <div className="descricao-servico-afrodite">
                    <div className='imagens-servico-afrodite'>
                        <img className="imagem-servico-afrodite-1" src={imagemServico1} width={350} height={350} alt="Imagem de uma mulher trabalhando" />
                    </div>
                    <div className="dizeres-descricao-afrodite">
                        <h1>DESCRIÇÃO</h1>
                        <h2>do Serviço</h2>
                        <span>Serão dois encontros, um presencial com duração de duas horas e outro online (via Zoom ou Google Meet) com duração de uma hora.</span>
                        <h3>PRIMEIRO ENCONTRO - Presencial</h3>
                        <span><b>Coloração Pessoal:</b> na análise de coloração pessoal identificarei as cores que mais realçam sua pele, olhos e cabelos, levando em conta fatores como temperatura e intensidade das cores.</span>
                        <br></br><br></br>
                        <span><b>Análise de Biotipo:</b> a análise do biotipo considera sua estrutura corporal, incluindo proporções, curvas e linhas, para escolher roupas que valorizem sua silhueta.</span>
                        <br></br><br></br>
                        <span><b>Análise Facial:</b> a análise facial avalia a forma do rosto, características dominantes e simetria para recomendar cortes de cabelo, óculos e estilos de maquiagem ideais.</span>
                        <h3>SEGUNDO ENCONTRO - Online</h3>
                        <span>Orientações, entrega e apresentação do seu <b>Dossiê Personalizado.</b> Este dossiê foi preparado para ajudar a destacar o melhor de você, focando em aspectos que acentuam sua beleza natural e promovem sua confiança pessoal.</span>
                    </div>
                </div>
                <div className="beneficios-afrodite">
                    <img className="imagem-beneficios-afrodite" src={imagemBeneficios} width={350} alt="Imagem de uma mulher bem vestida e completamente de branco" />
                    <div className="dizeres-beneficios-afrodite">
                        <h1>BENEFÍCIOS</h1>
                        <h2>da Consultoria</h2>
                        <span><b>Aumento da autoconfiança:</b> sentir-se mais segura e confortável com sua aparência e estilo;</span>
                        <br></br><br></br>
                        <span><b>Bem-estar emocional:</b> melhoria no bem-estar emocional, devido ao aumento da autoestima e autoconfiança;</span>
                        <br></br><br></br>
                        <span><b>Tomada de decisões estratégicas:</b> habilidade em fazer compras assertivas, evitando aquisições do que não funciona para você;</span>
                        <br></br><br></br>
                        <span><b>Combinando cores:</b> capacidade de combinar cores para ressaltar a sua beleza.</span>
                    </div>
                </div>
                <div className='material-personalizado-afrodite'>
                    <div className='titulo-e-subtitulo-material-personalizado-afrodite'>
                        <h1>MATERIAL</h1>
                        <h2>Personalizado</h2>
                    </div>
                    <div className='conteudo-material-personalizado-afrodite'>
                        <h3>O que você vai receber no final da consultoria:</h3>
                        <div className='bloco-cinza-afrodite'>
                            <div className='dizeres-bloco-cinza-afrodite'>
                                <div className='itens'>
                                    <img className="icone-check-material" src={iconeCheckMaterial} width={25} height={25} alt="Ícone de check" />
                                    <h2>CARTELA DE CORES</h2>
                                </div>
                                <div className='itens'>
                                    <img className="icone-check-material" src={iconeCheckMaterial} width={25} height={25} alt="Ícone de check" />
                                    <h2>CÍRCULO CROMÁTICO</h2>
                                </div>
                                <div className='itens'>
                                    <img className="icone-check-material" src={iconeCheckMaterial} width={25} height={25} alt="Ícone de check" />
                                    <h2>DOSSIÊ PERSONALIZADO CONTENDO:</h2>
                                </div>
                                <ul>
                                    <li>Indicação das cores que ressaltam sua beleza;</li>
                                    <li>Indicação de cortes de cabelo e maquiagens que valorizam a sua beleza;</li>
                                    <li>Informações de como valorizar o seu biotipo;</li>
                                    <li>Informações de como valorizar as suas formas faciais.</li>
                                </ul>
                            </div>
                            <div className='imagens-bloco-cinza-afrodite'>
                                <img className="imagem-material-afrodite-1" src={imagemMaterial1} width={200} alt="Imagem de uma mulher trabalhando" />
                                <img className="imagem-material-afrodite-2" src={imagemMaterial2} width={200} alt="Imagem de uma cartela de cores" />
                                <img className="imagem-material-afrodite-3" src={imagemMaterial3} width={200} alt="Imagem de um círculo cromático" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bonus-afrodite'>
                    <h1>BÔNUS</h1>
                    <h2>Exclusivo</h2>
                    <div className='bloco-cinza-afrodite'>
                        <div className='dizeres-bloco-cinza-afrodite'>
                            <div className='itens'>
                                <img className="icone-check-bonus" src={iconeCheckBonus} width={40} height={40} alt="Ícone de check" />
                                <span>O meu GUIA: Básica com Estilo – para um guarda-roupa versátil e elegante;</span>
                            </div>
                            <br></br>
                            <div className='itens'>
                                <img className="icone-check-bonus" src={iconeCheckBonus} width={40} height={40} alt="Ícone de check" />
                                <span>Acesso a minha pasta exclusiva do Pinterest, na qual compartilho dicas de tendências e looks para todas as estações do ano;</span>
                            </div>
                            <br></br>
                            <div className='itens'>
                                <img className="icone-check-bonus" src={iconeCheckBonus} width={40} height={40} alt="Ícone de check" />
                                <span>Presente personalizado.</span>
                            </div>
                        </div>
                        <div className='imagens-bloco-cinza-bonus-afrodite'>
                            <img className="imagem-bonus-afrodite-1" src={imagemBonus1} width={200} alt="Capa do guia Básica com Estilo" />
                            <img className="imagem-bonus-afrodite-2" src={imagemBonus2} width={200} alt="Imagem de uma xícara de café em cima de uma revista da Vogue" />
                            <img className="imagem-bonus-afrodite-3" src={imagemBonus3} width={200} alt="Imagem de uma mulher bem vestida e completamente de preto" />
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <Rodape />
            </footer>
        </div>
    );
}

export default Afrodite