import iconeInstagram from './assets/icone-instagram-rodape.png'
import iconeWhatsapp from './assets/icone-whatsapp-rodape.png'
import iconeEmail from './assets/icone-email-rodape.png'
import imagemEletronicos from './assets/imagem-eletronicos.png'
import "./Rodape.css"

const Rodape = () => {
    return (
        <div className="rodape">
            <div className="contatos-rodape">
                <h1>CONTATOS</h1>
                <div className="icones-e-contatos">
                    <div className="icone-instagram-rodape">
                        <img className="instagram" src={iconeInstagram} width={30} alt="Ícone do Instagram" />
                        <span>@vaniagonzalezoficial</span>
                    </div>
                    <div className="icone-whatsapp-rodape">
                        <img className="whatsapp" src={iconeWhatsapp} width={30} alt="Ícone do Whatsapp" />
                        <span>+55 (11) 96627-6632</span>
                    </div>
                    <div className="icone-email-rodape">
                        <img className="email" src={iconeEmail} width={30} alt="Ícone do E-mail" />
                        <span>contato@vaniagonzalez.com.br</span>
                    </div>
                </div>
            </div>
            <div className='imagens-rodape'>
                <img className="imagem-eletronicos" src={imagemEletronicos} width={320} alt="Imagens de computador e celular" />
            </div>
        </div>
    );
}

export default Rodape